import React, { useContext } from "react";
import Com_Login from "../../components/login/Com_Login";
import "./login.scss";
import { AuthContext } from "../../context/AuthContext";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

const Login = () => {

  const { user, dispatch } = useContext(AuthContext);
  const navigate = useLocation();
  const apiLink = "https://mail-api.notionsgroup.com/api/auth";
  const nameData = user?.details.name;
  
  
  const logoutUser = async () => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      await axios.get(`${apiLink}/logout`);
      localStorage.removeItem("user");
      dispatch({ type: "LOGOUT" });
      navigate("/login");
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error fetching data:", error);
      }
    }
    return () => controller.abort();
  };

  return (
    <div className="__fw">
      <div className="__mw">
        {user ? (
          <>
            <div className="login">
              <p>You already logedin as {nameData} pleace visit below link </p>
              <Link to={"/inquiry"} className="btn">
                {" "}
                Inquiry{" "}
              </Link>
              <span className="btn" onClick={logoutUser}>
                Logout
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="login">
              <p>LOGIN</p>
              <Com_Login />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
