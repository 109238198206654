import React from "react";
import "./catalog.scss";
import HTMLFlipBook from "react-pageflip";
import "./catalog.scss";
import catalog from "../../img/New Chips/chips catalog_compressed.pdf";
import Form from "../../components/form/Form";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";

const CatalogPageThree = () => {
  return (
    <div className="catalog __container">
      <Link style={{ color: "black" }} to="/index.php/catalog">
        <IoMdArrowRoundBack size={30} color="black" /> Back
      </Link>

      <div className="book">
        <HTMLFlipBook width={350} height={550}>
          <div className="demoPage">
            <img
              src={require("../../img/New Chips/Artboard 1@1.5x.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chips/Artboard 2@1.5x.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chips/CHIPS FLYER-01.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chips/CHIPS FLYER-02.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chips/CHIPS FLYER-03.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chips/CHIPS FLYER-04.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chips/CHIPS FLYER-05.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chips/CHIPS FLYER-06.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chips/CHIPS FLYER-07.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chips/CHIPS FLYER-08.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chips/CHIPS FLYER-09.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chips/CHIPS FLYER-10.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chips/CHIPS FLYER-11.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chips/CHIPS FLYER-12.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chips/CHIPS FLYER-13.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chips/CHIPS FLYER-14.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chips/CHIPS FLYER-15.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chips/CHIPS FLYER-16.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chips/CHIPS FLYER-17.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chips/CHIPS FLYER-18.jpg")}
              alt=""
            />
          </div>
          {/* <div className="demoPage">
            <img
              src={require("../../img/New Chips/CHIPS FLYER-19.jpg")}
              alt=""
            />
          </div> */}
          {/* <div className="demoPage">
            <img
              src={require("../../img/New Chips/CHIPS FLYER-20.jpg")}
              alt=""
            />
          </div> */}
        </HTMLFlipBook>
        <br />
        <div>
          <h3>Click on book to open </h3>
          <img
            className="icon_img"
            style={{ width: "80px" }}
            src={require("../../img/swipe-left.gif")}
            alt=""
          />
        </div>
        <div className="down">
          <Form />
          <div className="cat_down">
            <h1></h1>

            <h3>
              Click on below button to download <br /> PDF file of Catalog
            </h3>

            <a
              href={catalog}
              download="Catalog"
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn">Download PDF</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CatalogPageThree;
