import React from 'react'
import HTMLFlipBook from "react-pageflip";
import './catalog.scss'
// import catalog from '../../img/Catalogue.pdf';
import Form from '../../components/form/Form';
import { Link } from 'react-router-dom';


const Catalog = () => {
  const scrillTop =()=>{
    window.scroll(0,0)
  }

  return (
    <div className="catalog __container">
      <br />
      <br />
      <h1>E-Catalogues</h1>
      <div className="book_tab">
        <Link to="/chocolate-catalogue" onClick={scrillTop}>
          <div className="book_card">
            <div>
              <img
                className="icon_img"
                style={{ width: "100%" }}
                src={require("../../img/chocolate-02.webp")}
                alt=""
              />
            </div>
          </div>
        </Link>
        <Link to="/luxury-chocolate-catalogue" onClick={scrillTop}>
          <div className="book_card">
            <div>
              <img
                className="icon_img"
                style={{ width: "100%" }}
                src={require("../../img/Luxury Chocolate-02.webp")}
                alt=""
              />
            </div>
          </div>
        </Link>
        <Link to="/snacks-catalogue" onClick={scrillTop}>
          <div className="book_card">
            <div>
              <img
                className="icon_img"
                style={{ width: "100%" }}
                src={require("../../img/chips catalog-02.webp")}
                alt=""
              />
            </div>
          </div>
        </Link>
        <Link to="/private-label-catalogue" onClick={scrillTop}>
          <div className="book_card">
            <div>
              <img
                className="icon_img"
                style={{ width: "100%" }}
                src={require("../../img/Artboard 2@1.5x.webp")}
                alt=""
              />
            </div>
          </div>
        </Link>
      </div>
      {/* <div className="location">
        <h2>ISM Middle East Location Map</h2>
        <br />
        <img src={require("../../img/map.jpg")} alt="" />
      </div> */}
    </div>
  );
}

export default Catalog
