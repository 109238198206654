import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext.js";

export const ProtectedRoute = ({ children }) => {
  const { user } = useContext(AuthContext);

  const email = user?.details.email;

  // console.log(user.details.email);

  if (!email) {
    return <Navigate to="/login" />;
  }

  return children;
};
