import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./login.scss";
import { IoCloseCircleOutline } from "react-icons/io5";
import { AuthContext } from "../../context/AuthContext.js";

const Com_Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const devApiLink = "http://localhost:3003/api/auth";
      const apiLink = "https://mail-api.notionsgroup.com/api/auth";

  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);

  const registerUser = async (ev) => {
    ev.preventDefault();

    dispatch({ type: "LOGIN_START" });

    try {
      const credentials = {
        email,
        password,
      };

      await axios.post(`${apiLink}/userRegister`, {
        name,
        email,
        password,
      });
      const login = await axios.post(`${apiLink}/login`, credentials);
      dispatch({ type: "LOGIN_SUCCESS", payload: login.data });
      navigate("/inquiry");
      setError("");
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError(
          "Registration failed. Please check the provided information and try again."
        );
      } else if (error.response && error.response.status === 500) {
        setError("Server error. Please try again later");
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
      dispatch({ type: "LOGIN_FAILURE", payload: error });
    }
  };

  return (
    <>
      <form className="_form" onSubmit={registerUser}>
        <input
          type="text"
          placeholder="John Doe"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="email"
          placeholder="your@email.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button>Register</button>

        <small style={{ color: "red" }}>{error}</small>
      </form>
    </>
  );
};

export default Com_Register;
