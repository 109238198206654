import React from 'react'
import "./catalog.scss";
import HTMLFlipBook from "react-pageflip";
import "./catalog.scss";
import catalog from "../../img/New Chocolate/chocolate_compressed.pdf";
import Form from "../../components/form/Form";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from 'react-router-dom';

const CatalogPageOne = () => {
  return (
    <div className="catalog __container">
      <Link style={{ color: "black" }} to="/index.php/catalog">
        <IoMdArrowRoundBack size={30} color="black" /> Back
      </Link>

      <div className="book">
        <HTMLFlipBook width={350} height={550}>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/chocolate-01.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/chocolate-02.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-01.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-02.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-03.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-04.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-05.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-06.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-07.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-08.jpg")}
              alt=""
            />
          </div>

          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-09.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-10.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-11.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-12.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-13.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-14.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-15.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-16.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-17.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-18.jpg")}
              alt=""
            />
          </div>
          {/* <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-19.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-20.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-21.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-22.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-23.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-24.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-25.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-26.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-27.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-28.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-29.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-30.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-31.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-32.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-33.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-34.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-35.jpg")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Chocolate/Chcolate-36.jpg")}
              alt=""
            />
          </div> */}
        </HTMLFlipBook>
        <br />
        <div>
          <h3>Click on book to open </h3>
          <img
            className="icon_img"
            style={{ width: "80px" }}
            src={require("../../img/swipe-left.gif")}
            alt=""
          />
        </div>

        <div className="down">
          <Form />
          <div className="cat_down">
            <h1></h1>

            <h3>
              Click on below button to download <br /> PDF file of Catalog
            </h3>

            <a
              href={catalog}
              download="Catalog"
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn">Download PDF</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CatalogPageOne;
