import React from 'react'
import Com_Register from '../../components/login/Com_Register'
import "./login.scss";

const Register = () => {
  return (
    <div className="__fw">
      <div className="__mw">
        <div className="reg">
          <p>REGISTER</p>
          <Com_Register />
        </div>
      </div>
    </div>
  );
}

export default Register
